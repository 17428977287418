import React, { useContext, useEffect, useState } from "react";
import Pagination from "../components/pagination";
import { Link, useSearchParams } from "react-router-dom";
import { NewsContext } from "../context/NewsContext.jsx";
import MarkdownDisplay from "../components/markdownDisplay.jsx";
import CompanyBar from "../components/companyBar.jsx";

const MAX_ITEMS_PER_PAGE = 6;
const MAX_PREVIEW_LENGTH = 255;

const NewsCatalogue = () => {
  const { news } = useContext(NewsContext);

  const [filteredArticles, setFilteredArticles] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [initialized, setInitialized] = useState(false);

  const updateUrlParams = (newParams) => {
    setSearchParams(newParams);
  };

  useEffect(() => {
    setTotalPages(Math.ceil(filteredArticles.length / MAX_ITEMS_PER_PAGE) || 1);
    if (searchParams.get("page") == null) {
      setCurrentPage(1);
    }
  }, [filteredArticles]);

  useEffect(() => {
    const pageParam = searchParams.get("page");
    if (!!pageParam) {
      setCurrentPage(Number(pageParam));
    }
    setInitialized(true);
  }, [searchParams]);

  useEffect(() => {
    if (news.length > 0) {
      setFilteredArticles(news);
    }
  }, [news]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);

    const newParams = new URLSearchParams(searchParams);
    newParams.set("page", pageNumber.toString());
    updateUrlParams(newParams);

    window.scrollTo(0, 0);
  };

  return (
      <>
        <CompanyBar/>
        <div className="container mx-auto p-8 max-w-7xl">
          <div
              className={`grid gap-8 ${
                  filteredArticles.length === 1
                      ? "grid-cols-1 max-w-xl mx-auto"
                      : "grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
              }`}
          >
            {filteredArticles
                .slice(
                    (currentPage - 1) * MAX_ITEMS_PER_PAGE,
                    MAX_ITEMS_PER_PAGE * currentPage
                )
                .map(newsPreview)}
          </div>
          <div className="flex flex-col items-center sm:basis-4/5">
            {filteredArticles.length > MAX_ITEMS_PER_PAGE && (
                <Pagination
                    currentPage={currentPage}
                    totalPageCount={totalPages}
                    onPageChange={handlePageChange}
                    sx="mt-4 sm:mt-8"
                />
            )}
          </div>
        </div>
      </>
  );
};

/**@param article {NewsArticle}*/
const newsPreview = (article) => {
  const articleBodyPreview = () => {
    const body = article.body;
    if (body.length > MAX_PREVIEW_LENGTH) {
      return body.substring(0, MAX_PREVIEW_LENGTH) + "...";
    } else {
      return body;
    }
  };
  return (
    <Link key={article.id} to={`/news/${article.id}`}>
      <div className="bg-white border border-gray-300 shadow-md overflow-hidden hover:shadow-lg transition-shadow flex flex-col h-full">
        <img
          src={article.imageUrl}
          alt="Article picture"
          className="w-full h-48 object-cover"
        />
        <div className="p-4 flex flex-col flex-grow">
          <h3 className="text-xl font-semibold group-hover:text-primary transition-colors">
            {article.title}
          </h3>
          <div className="flex flex-row justify-between mb-1">
            <p className="text-gray-600 text-sm mt-0">
              {article.createdAt.toLocaleDateString()}
            </p>
            {!article.published && (
              <p className="text-white text-sm mt-2 p-1 bg-primary">
                ej publicerad
              </p>
            )}
          </div>
          <MarkdownDisplay data={articleBodyPreview()}></MarkdownDisplay>
        </div>
      </div>
    </Link>
  );
};

export default NewsCatalogue;
