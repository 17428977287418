import React, { useCallback, useState } from "react";
import Modal from "./modal.jsx";
import Button from "./button.jsx";
import {
  login,
  register,
  verifyOtp,
  resendOtp,
  verifyForgotPasswordOtp,
  sendForgotPasswordOtp,
} from "../httpService.js";

const MODES = {
  LOGIN: "login",
  REGISTER: "register",
  OTP_EMAIL_VERIFICATION: "otpEmailVerification",
  FORGOT_PASSWORD: "forgotPassword",
  OTP_FORGOT_PASSWORD: "otpForgotPassword",
};

const LoginOrRegisterModal = ({ onClose, onCompleted }) => {
  const [jwt, setJwt] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [mode, setMode] = useState(MODES.LOGIN);
  const [otpCode, setOtpCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [newPassword, setNewPassword] = useState("");

  const doLogin = useCallback(async () => {
    try {
      const response = await login({
        email,
        password,
      });

      const data = await response.json();

      if (data.verified) {
        onCompleted(data);
      } else {
        setJwt(data.jwt);
        setMode(MODES.OTP_EMAIL_VERIFICATION);
      }
    } catch (error) {
      setErrorMessage("Ett fel inträffade. Vänligen försök igen.");
    }
  }, [email, password, onCompleted]);

  const doRegister = useCallback(async () => {
    try {
      const response = await register({
        email,
        password,
      });

      const data = await response.json();
      setJwt(data.jwt);
      setMode(MODES.OTP_EMAIL_VERIFICATION);
    } catch (error) {
      setErrorMessage("Ett fel inträffade. Vänligen försök igen.");
    }
  }, [email, password]);

  const verifyOtpCode = useCallback(async () => {
    try {
      const response = await verifyOtp(otpCode, jwt);
      const data = await response.json();

      onCompleted(data);
    } catch (error) {
      setErrorMessage("Ett fel inträffade. Vänligen försök igen.");
    }
  }, [otpCode, onCompleted, jwt]);

  const verifyForgotPasswordOtpCode = useCallback(async () => {
    try {
      const response = await verifyForgotPasswordOtp(
        otpCode,
        email,
        newPassword
      );

      const data = await response.json();
      onCompleted(data);
    } catch (error) {
      setErrorMessage("Ett fel inträffade. Vänligen försök igen.");
    }
  }, [otpCode, onCompleted, email, newPassword]);

  const resendOtpCode = useCallback(async () => {
    try {
      await resendOtp(jwt);
      setErrorMessage("En ny kod har skickats till din e-postadress.");
    } catch (error) {
      setErrorMessage("Kunde inte skicka ny kod. Vänligen försök igen senare.");
    }
  }, [jwt]);

  const handleSendForgotPasswordOtp = useCallback(async () => {
    try {
      await sendForgotPasswordOtp(email);
      setMode(MODES.OTP_FORGOT_PASSWORD);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage("Ett fel inträffade. Vänligen försök igen.");
    }
  }, [email]);

  const renderLoginForm = () => (
    <div className="flex flex-col gap-4">
      {errorMessage && (
        <div className="text-red-500 text-sm">{errorMessage}</div>
      )}
      <div>
        <label
          htmlFor="email"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Email
        </label>
        <input
          type="text"
          id="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
          onKeyDown={async (event) => {
            if (event.key === "Enter") {
              doLogin();
            }
          }}
        />
      </div>
      <div>
        <label
          htmlFor="password"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Lösenord
        </label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
          onKeyDown={async (event) => {
            if (event.key === "Enter") {
              doLogin();
            }
          }}
        />
      </div>
      <Button onClick={() => doLogin()}>Logga in</Button>
      <p className="text-sm">
        Har du inget konto?{" "}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setMode(MODES.REGISTER);
          }}
          className="text-blue-500"
        >
          Registrera dig
        </a>
      </p>
      <p className="text-sm">
        Glömt ditt lösenord?{" "}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setMode(MODES.FORGOT_PASSWORD);
            setErrorMessage("");
          }}
          className="text-blue-500"
        >
          Återställ här
        </a>
      </p>
    </div>
  );

  const renderRegisterForm = () => (
    <div className="flex flex-col gap-4">
      {errorMessage && (
        <div className="text-red-500 text-sm">{errorMessage}</div>
      )}
      <div>
        <label
          htmlFor="email"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Email
        </label>
        <input
          type="text"
          id="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
          onKeyDown={async (event) => {
            if (event.key === "Enter") {
              doRegister();
            }
          }}
        />
      </div>
      <div>
        <label
          htmlFor="password"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Lösenord
        </label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
          onKeyDown={async (event) => {
            if (event.key === "Enter") {
              doRegister();
            }
          }}
        />
      </div>
      <Button onClick={() => doRegister()}>Registrera</Button>
      <p className="text-sm">
        Har du redan ett konto?{" "}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setMode(MODES.LOGIN);
          }}
          className="text-blue-500"
        >
          Logga in
        </a>
      </p>
    </div>
  );

  const renderOtpEmailVerification = () => (
    <div className="flex flex-col gap-4">
      {errorMessage && (
        <div className="text-red-500 text-sm">{errorMessage}</div>
      )}
      <p>Ange den 6-siffriga koden som skickats till din e-postadress.</p>
      <div>
        <label
          htmlFor="otpCode"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Verifieringskod
        </label>
        <input
          type="text"
          id="otpCode"
          value={otpCode}
          onChange={(event) => setOtpCode(event.target.value)}
          className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
          onKeyDown={async (event) => {
            if (event.key === "Enter") {
              verifyOtpCode();
            }
          }}
        />
      </div>
      <Button onClick={() => verifyOtpCode()}>Verifiera</Button>
      <p className="text-sm">
        Har du inte fått koden?{" "}
        <a
          href="#"
          onClick={async (e) => {
            e.preventDefault();
            await resendOtpCode();
          }}
          className="text-blue-500"
        >
          Skicka koden igen
        </a>
      </p>
    </div>
  );

  const renderOtpForgotPasswordVerification = () => (
    <div className="flex flex-col gap-4">
      {errorMessage && (
        <div className="text-red-500 text-sm">{errorMessage}</div>
      )}
      <p>Ange den 6-siffriga koden som skickats till din e-postadress.</p>
      <div className="flex flex-col gap-4">
        <div>
          <label
            htmlFor="otpCode"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Verifieringskod
          </label>
          <input
            type="text"
            id="otpCode"
            value={otpCode}
            onChange={(event) => setOtpCode(event.target.value)}
            className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
          />
        </div>
        <div>
          <label
            htmlFor="password"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Nytt Lösenord
          </label>
          <input
            type="password"
            id="password"
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
            className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
          />
        </div>
      </div>
      <Button onClick={() => verifyForgotPasswordOtpCode()}>Verifiera</Button>
      <p className="text-sm">
        Har du inte fått koden?{" "}
        <a
          href="#"
          onClick={async (e) => {
            e.preventDefault();
            await sendForgotPasswordOtp(email);
            setErrorMessage("En ny kod har skickats till din e-postadress.");
          }}
          className="text-blue-500"
        >
          Skicka koden igen
        </a>
      </p>
    </div>
  );

  const renderForgotPasswordForm = () => (
    <div className="flex flex-col gap-4">
      {errorMessage && (
        <div className="text-red-500 text-sm">{errorMessage}</div>
      )}
      <p>Ange din e-postadress för att återställa ditt lösenord.</p>
      <div>
        <label
          htmlFor="email"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Email
        </label>
        <input
          type="text"
          id="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSendForgotPasswordOtp();
            }
          }}
        />
      </div>
      <Button onClick={handleSendForgotPasswordOtp}>
        Skicka verifieringskod
      </Button>
      <p className="text-sm">
        Kommit på lösenordet?{" "}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setMode(MODES.LOGIN);
            setErrorMessage("");
          }}
          className="text-blue-500"
        >
          Logga in
        </a>
      </p>
    </div>
  );

  const renderContent = () => {
    switch (mode) {
      case MODES.LOGIN:
        return renderLoginForm();
      case MODES.REGISTER:
        return renderRegisterForm();
      case MODES.OTP_EMAIL_VERIFICATION:
        return renderOtpEmailVerification();
      case MODES.OTP_FORGOT_PASSWORD:
        return renderOtpForgotPasswordVerification();
      case MODES.FORGOT_PASSWORD:
        return renderForgotPasswordForm();
      default:
        return null;
    }
  };

  const getHeader = () => {
    switch (mode) {
      case MODES.LOGIN:
        return "Logga in";
      case MODES.REGISTER:
        return "Registrera dig";
      case MODES.OTP_EMAIL_VERIFICATION:
      case MODES.OTP_FORGOT_PASSWORD:
        return "Verifiera din e-postadress";
      case MODES.FORGOT_PASSWORD:
        return "Glömt Lösenord";
      default:
        return "";
    }
  };

  return (
    <Modal header={getHeader()} showCloseButton={false} onClose={onClose}>
      {renderContent()}
    </Modal>
  );
};

export default LoginOrRegisterModal;
