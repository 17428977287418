import React from "react";
import { Helmet } from "react-helmet-async";
import Heading from "../components/heading";
import { termsAndConditions } from "../termsAndConditions";
import CompanyBar from "../components/companyBar.jsx";

const TermsAndConditions = () => {
  return (
    <div>
      <Helmet>
        <title>AS Maskiner - Allmänna villkor</title>
      </Helmet>
      <CompanyBar/>
      <div className="container mx-auto p-8 max-w-7xl">
        <Heading sx="mb-4 w-fit mb-8" type="h1">
          Allmänna villkor
        </Heading>
        {termsAndConditions.map((section, index) => (
          <div key={index} className="mb-10">
            <Heading bg="inherit" color="black" size="lg:text-xl sm:text-md">
              {section.title}
            </Heading>
            <ol
              style={{ listStyleType: "lower-alpha" }}
              className="list-inside list-decimal pl-4 mt-4 text-gray-700 text-lg"
            >
              {section.points.map((point, idx) => (
                <li key={idx} className="mt-2 text-sm sm:text-lg">
                  {point}
                </li>
              ))}
            </ol>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermsAndConditions;
