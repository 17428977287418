import Markdown from "react-markdown";

const MarkdownDisplay = ({ data, sx, ...props }) => (
  <div
    {...props}
    className={`[&_h3]:text-lg [&_h3]:font-bold [&_h2]:text-xl [&_h2]:font-bold [&_p]:mt-4 [&_p]:mb-4 [&_ul]:list-disc [&_ul]:ml-4 ${
      sx ? sx : ""
    } text-wrap overflow-wrap`} // Add text wrapping styles here
  >
    <Markdown>{data}</Markdown>
  </div>
);

export default MarkdownDisplay;
