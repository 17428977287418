import React, { useContext, useEffect, useState, useMemo } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { MachineContext } from "../context/MachineContext";
import { contactInformation } from "../constants";
import MachinePreview from "../components/machinePreview";
import Manufacturers from "../components/manufacturers";
import { UserContext } from "../context/UserContext";
import Button from "../components/button";
import {
  deleteMachine,
  publishMachine,
  unpublishMachine,
  updateFeatured,
  updateMachine,
  uploadFile,
} from "../httpService";
import MarkdownDisplay from "../components/markdownDisplay";
import Heading from "../components/heading";
import Modal from "../components/modal";
import { Helmet } from "react-helmet-async";
import Input from "../components/input";
import ImagesEditor from "../components/imagesEditor";

const PROVIDER_INFO = {
  hoechsmann:
    "AS Maskiner Sverige AB har agenturen i Sverige för Höchsmann. Denna maskin kommer från Höchsmann och kan enbart köpas via AS Maskiner.",
};

const PhoneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="20"
    height="20"
    viewBox="0 0 50 50"
  >
    <path d="M 14 3.9902344 C 8.4886661 3.9902344 4 8.4789008 4 13.990234 L 4 35.990234 C 4 41.501568 8.4886661 45.990234 14 45.990234 L 36 45.990234 C 41.511334 45.990234 46 41.501568 46 35.990234 L 46 13.990234 C 46 8.4789008 41.511334 3.9902344 36 3.9902344 L 14 3.9902344 z M 18.005859 12.033203 C 18.633859 12.060203 19.210594 12.414031 19.558594 12.957031 C 19.954594 13.575031 20.569141 14.534156 21.369141 15.785156 C 22.099141 16.926156 22.150047 18.399844 21.498047 19.589844 L 20.033203 21.673828 C 19.637203 22.237828 19.558219 22.959703 19.824219 23.595703 C 20.238219 24.585703 21.040797 26.107203 22.466797 27.533203 C 23.892797 28.959203 25.414297 29.761781 26.404297 30.175781 C 27.040297 30.441781 27.762172 30.362797 28.326172 29.966797 L 30.410156 28.501953 C 31.600156 27.849953 33.073844 27.901859 34.214844 28.630859 C 35.465844 29.430859 36.424969 30.045406 37.042969 30.441406 C 37.585969 30.789406 37.939797 31.366141 37.966797 31.994141 C 38.120797 35.558141 35.359641 37.001953 34.556641 37.001953 C 34.000641 37.001953 27.316344 37.761656 19.777344 30.222656 C 12.238344 22.683656 12.998047 15.999359 12.998047 15.443359 C 12.998047 14.640359 14.441859 11.879203 18.005859 12.033203 z" />
  </svg>
);

const Machine = () => {
  let { id } = useParams();
  const { machines, reinitialize } = useContext(MachineContext);
  const [activeImage, setActiveImage] = useState(null);
  const [activeVideo, setActiveVideo] = useState(null);
  const [fullImageToDisplay, setFullImageToDisplay] = useState(null);
  const { isAdmin, getJwt } = useContext(UserContext);
  const [updatedMachine, setUpdatedMachine] = useState({
    price: 0,
    attributes: [],
    description: "",
    title: "",
    category: "",
    buildYear: "",
    manufacturer: "",
    subCategory: "",
    images: [],
  });
  const navigate = useNavigate();

  const machine = useMemo(
    () => machines.find((m) => m.id == id),
    [machines, id]
  );

  useEffect(() => {
    if (!!machine) {
      setActiveImage(machine.images[0]);
      setUpdatedMachine({
        price: machine.price || 0,
        attributes: machine.attributes || [],
        description: machine.description || "",
        title: machine.title || "",
        category: machine.category || "",
        buildYear: machine.buildYear || "",
        manufacturer: machine.manufacturer || "",
        subCategory: machine.subCategory || "",
        images: machine.images || [],
      });
    }
  }, [machine]);

  const productInformation = [
    ...(machine?.id
      ? [
          {
            key: "Artikelnummer",
            value: machine.id,
          },
        ]
      : []),
    ...(machine?.condition
      ? [
          {
            key: "Skick",
            value: machine.condition,
          },
        ]
      : []),
    ...(machine?.buildYear
      ? [
          {
            key: "Årsmodell",
            value: machine.buildYear,
          },
        ]
      : []),
    ...(machine?.category
      ? [
          {
            key: "Kategori",
            value: machine.category,
          },
        ]
      : []),
    ...(machine?.manufacturer
      ? [
          {
            key: "Tillverkare",
            value: machine.manufacturer,
          },
        ]
      : []),
    ...(machine?.locationString
      ? [
          {
            key: "Plats",
            value: machine.locationString,
          },
        ]
      : []),
  ];

  const navigateToNextImage = () => {
    const currentIndex = machine.images.indexOf(fullImageToDisplay);
    const nextIndex = (currentIndex + 1) % machine.images.length;
    setFullImageToDisplay(machine.images[nextIndex]);
  };

  const navigateToPreviousImage = () => {
    const currentIndex = machine.images.indexOf(fullImageToDisplay);
    const previousIndex =
      (currentIndex - 1 + machine.images.length) % machine.images.length;
    setFullImageToDisplay(machine.images[previousIndex]);
  };

  const handleAttributeChange = (index, field, value) => {
    setUpdatedMachine((prev) => {
      const updatedAttributes = [...prev.attributes];
      updatedAttributes[index][field] = value;
      return { ...prev, attributes: updatedAttributes };
    });
  };

  const addAttribute = () => {
    setUpdatedMachine((prev) => {
      return {
        ...prev,
        attributes: [...prev.attributes, { key: "", value: "" }],
      };
    });
  };

  const removeAttribute = (index) => {
    setUpdatedMachine((prev) => {
      const updatedAttributes = [...prev.attributes];
      updatedAttributes.splice(index, 1);
      return { ...prev, attributes: updatedAttributes };
    });
  };

  if (machines.length > 0 && !machine) return MachineNotFound();

  return (
    !!machine && (
      <>
        <Helmet>
          <title>AS Maskiner - {machine.title}</title>
          <meta
            name="description"
            content={`Upptäck ${machine.title} hos AS Maskiner. En idealisk lösning för trä- och plastindustrin, erbjuder hög prestanda och pålitlighet. Läs mer om funktioner och fördelar.`}
          />
          <meta
            name="keywords"
            content={`AS Maskiner, begagnade maskiner, ${machine.title}, begagnad ${machine.category}, ${machine.manufacturer}, begagnad CNC, begagnad kantlistmaskin, begagnad justersåg`}
          />
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org",
              "@type": "Product",
              "name": "${machine.title}",
              "image": "${machine.images[0]}",
              "description": "Upptäck ${machine.title} hos AS Maskiner. En idealisk lösning för trä- och plastindustrin, erbjuder hög prestanda och pålitlighet. Läs mer om funktioner och fördelar.",
              "brand": {
                "@type": "Brand",
                "name": "${machine.manufacturer}"
              },
              "sku": "${machine.id}",
              "category": "${machine.category}"
            }`}
          </script>
        </Helmet>
        <div className="container mx-auto max-w-5xl p-4 sm:p-0 sm:pt-8 sm:pb-8">
          <h1 className="text-2xl font-bold mb-2 sm:mb-0 sm:pl-4">
            {machine.title}
          </h1>
          {isAdmin && (
            <div className="p-4 space-x-4 flex">
              <div>
                <Button
                  onClick={() => {
                    if (
                      window.confirm(
                        "Är du säker på att du vill ta bort den här maskinen?"
                      )
                    ) {
                      deleteMachine({ id: machine.id, jwt: getJwt() })
                        .then(() => navigate("/"))
                        .catch((err) => alert("Kunde inte ta bort maskinen"));
                    }
                  }}
                >
                  Ta bort maskin
                </Button>
              </div>
              {}
              {machine.published ? (
                <>
                  <div>
                    <Button
                      onClick={() => {
                        if (
                          window.confirm(
                            "Är du säker på att du vill avpublicera den här maskinen?"
                          )
                        ) {
                          unpublishMachine({ id: machine.id, jwt: getJwt() })
                            .then(reinitialize)
                            .catch((err) =>
                              alert("Kunde inte avpublicera maskinen")
                            );
                        }
                      }}
                    >
                      Avpublicera maskin
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={() =>
                        updateFeatured({
                          id: machine.id,
                          featured: !machine.featured,
                          jwt: getJwt(),
                        })
                          .then(reinitialize)
                          .catch((err) =>
                            alert("Kunde inte ta framhäva maskin")
                          )
                      }
                    >
                      {machine.featured ? "Sluta framhäv" : "Framhäv maskin"}
                    </Button>
                  </div>
                </>
              ) : (
                <div>
                  <Button
                    onClick={() => {
                      if (
                        window.confirm(
                          "Är du säker på att du vill publicera den här maskinen?"
                        )
                      ) {
                        publishMachine({ id: machine.id, jwt: getJwt() })
                          .then(reinitialize)
                          .catch((err) =>
                            alert("Kunde inte publicera maskinen")
                          );
                      }
                    }}
                  >
                    Publicera maskin
                  </Button>
                </div>
              )}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={`/machines/${machine.id}/quotation/create`}
              >
                <Button>Skapa offert</Button>
              </Link>
            </div>
          )}
          <div className="flex flex-col md:flex-row gap-4 sm:p-4">
            <div className="w-full md:w-1/2">
              <div className="w-full h-[15rem] md:h-[20rem] mb-2">
                {activeImage && (
                  <img
                    src={activeImage}
                    alt={machine.title}
                    className="w-full h-full object-cover cursor-pointer"
                    onClick={() => setFullImageToDisplay(activeImage)}
                  />
                )}
                {activeVideo && (
                  <video
                    src={activeVideo}
                    autoPlay={true}
                    controls={true}
                    className="w-full h-full object-cover"
                  >
                    Your browser does not support the video tag
                  </video>
                )}
              </div>
              <div className="flex overflow-x-auto gap-2">
                {machine.videos?.map((videoUrl, index) => (
                  <div
                    key={`video_${index}`}
                    className="w-24 h-24 relative cursor-pointer flex-shrink-0"
                    onClick={() => {
                      setActiveVideo(videoUrl);
                      setActiveImage(null);
                    }}
                  >
                    <video
                      src={videoUrl}
                      autoPlay={false}
                      controls={false}
                      alt={`Thumbnail ${index}`}
                      className="w-full h-full object-cover"
                    >
                      Your browser does not support the video tag
                    </video>
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                      <span className="text-white text-2xl">▶️</span>
                    </div>
                  </div>
                ))}
                {machine.images.map((imageUrl, index) => (
                  <div
                    className="w-24 h-24 relative cursor-pointer flex-shrink-0"
                    key={`image_${index}`}
                  >
                    <img
                      src={imageUrl}
                      alt={`Thumbnail ${index}`}
                      className="w-full h-full object-cover"
                      onClick={() => {
                        setActiveImage(imageUrl);
                        setActiveVideo(null);
                      }}
                    />
                  </div>
                ))}
              </div>
              <div className="flex flex-col mt-4">
                <div className="bg-white p-4 border">
                  <div className="flex flex-col justify-between mb-4">
                    <div className="flex items-center">
                      <span className="text-green-500 mr-2">✓</span>
                      <h3 className="text-sm font-semibold text-green">
                        Tillgänglig
                      </h3>
                    </div>
                    {!!machine.price && (
                      <h3 className="text-2xl font-semibold mt-2">
                        {new Intl.NumberFormat("sv-SE", {
                          style: "currency",
                          currency: machine.currency || "SEK",
                        }).format(machine.price)}
                      </h3>
                    )}
                  </div>
                  <div className="border-t pt-4">
                    <div className="flex gap-2 items-center">
                      <Link
                        to={`/machines/${machine.id}/quotation`}
                        data-testid={`machine-${machine.id}-quotation-link`}
                      >
                        <Button>Begär offert</Button>
                      </Link>
                      <div className="flex items-center gap-1">
                        <span className="text-lg">eller</span>

                        <Link
                          to={`tel:${contactInformation.telephoneNumber}`}
                          className="hover:underline flex items-center gap-1"
                        >
                          <PhoneIcon />
                          {contactInformation.telephoneNumber}.
                        </Link>
                      </div>
                    </div>
                    <p className="text-sm mt-4">
                      Vi är tillgängliga 9-17 på vardagar.
                    </p>
                    {machine.provider && PROVIDER_INFO[machine.provider] && (
                      <p className="text-xs mt-4 font-bold">
                        {PROVIDER_INFO[machine.provider]}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-4 w-full md:w-1/2">
              <Details
                heading="Produktinformation"
                attributes={productInformation}
              />
              <Details
                heading="Tekniska specifikationer"
                attributes={machine.attributes}
              />
            </div>
          </div>
          {machine.description && (
            <div className="mt-4">
              <Heading sx="mb-4 w-fit">Ytterligare information</Heading>
              <MarkdownDisplay data={machine.description} />
            </div>
          )}
          {isAdmin && (
            <div className="flex flex-col gap-8 border border-primary p-4">
              <Heading style={{ width: "fit-content" }}>
                Ändra information
              </Heading>
              <div>
                <Input
                  id="title"
                  label="Titel"
                  type="text"
                  name="title"
                  value={updatedMachine.title}
                  onChange={(e) =>
                    setUpdatedMachine({
                      ...updatedMachine,
                      title: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <Input
                  id="price"
                  label="Pris"
                  type="number"
                  name="price"
                  value={updatedMachine.price}
                  onChange={(e) =>
                    setUpdatedMachine({
                      ...updatedMachine,
                      price: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <Input
                  id="category"
                  label="Kategori"
                  type="text"
                  name="category"
                  value={updatedMachine.category}
                  onChange={(e) =>
                    setUpdatedMachine({
                      ...updatedMachine,
                      category: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <Input
                  id="buildYear"
                  label="Årsmodell"
                  type="text"
                  name="buildYear"
                  value={updatedMachine.buildYear}
                  onChange={(e) =>
                    setUpdatedMachine({
                      ...updatedMachine,
                      buildYear: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <Input
                  id="manufacturer"
                  label="Tillverkare"
                  type="text"
                  name="manufacturer"
                  value={updatedMachine.manufacturer}
                  onChange={(e) =>
                    setUpdatedMachine({
                      ...updatedMachine,
                      manufacturer: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <Input
                  id="subCategory"
                  label="Underkategori"
                  type="text"
                  name="subCategory"
                  value={updatedMachine.subCategory}
                  onChange={(e) =>
                    setUpdatedMachine({
                      ...updatedMachine,
                      subCategory: e.target.value,
                    })
                  }
                />
              </div>
              <ImagesEditor
                images={updatedMachine.images}
                onChange={(newImages) =>
                  setUpdatedMachine({
                    ...updatedMachine,
                    images: newImages,
                  })
                }
                uploadImageFn={(image, compress) =>
                  uploadFile({
                    file: image,
                    jwt: getJwt(),
                    compress,
                  })
                }
              />
              <div>
                <h3 className="text-xl font-bold mb-4">
                  Tekniska specifikationer
                </h3>
                {updatedMachine.attributes.map((attribute, index) => (
                  <div
                    key={index}
                    className="flex justify-end items-end mb-4 gap-2"
                  >
                    <div className="flex-1">
                      <Input
                        id={`key_${index}`}
                        label="Nyckel"
                        type="text"
                        name="key"
                        value={attribute.key}
                        onChange={(e) =>
                          handleAttributeChange(index, "key", e.target.value)
                        }
                      />
                    </div>
                    <div className="flex-1">
                      <Input
                        id={`value_${index}`}
                        label="Värde"
                        type="text"
                        name="value"
                        value={attribute.value}
                        onChange={(e) =>
                          handleAttributeChange(index, "value", e.target.value)
                        }
                      />
                    </div>
                    <Button onClick={() => removeAttribute(index)}>
                      Ta bort
                    </Button>
                  </div>
                ))}
                <Button onClick={addAttribute}>Lägg till specifikation</Button>
              </div>
              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Beskrivning (Markdown)
                </label>
                <textarea
                  id="description"
                  name="description"
                  rows="6"
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-500 focus:border-indigo-500"
                  value={updatedMachine.description}
                  onChange={(e) =>
                    setUpdatedMachine({
                      ...updatedMachine,
                      description: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mt-2">
                <h3 className="font-semibold mb-1">Förhandsgranskning</h3>
                <div className="border p-2">
                  <MarkdownDisplay data={updatedMachine.description} />
                </div>
              </div>
              <div>
                <Button
                  onClick={() =>
                    updateMachine({
                      id: machine.id,
                      machine: updatedMachine,
                      jwt: getJwt(),
                    })
                      .then(() => {
                        reinitialize();
                        alert("Maskin uppdaterad");
                      })
                      .catch((err) => alert("Kunde inte uppdatera maskin"))
                  }
                >
                  Spara
                </Button>
              </div>
            </div>
          )}
        </div>
        <RecommendedSection
          activeMachineId={machine.id}
          category={machine.category}
        />
        <Manufacturers />
        {fullImageToDisplay && (
          <Modal
            header={`Bild ${machine.images.indexOf(fullImageToDisplay) + 1}`}
            onClose={() => setFullImageToDisplay(null)}
            size="w-11/12 sm:w-1/2"
          >
            <FullImageDisplay
              nextFn={navigateToNextImage}
              previousFn={navigateToPreviousImage}
              imageToDisplay={fullImageToDisplay}
              machineTitle={machine.title}
              closeFn={() => setFullImageToDisplay(null)}
            />
          </Modal>
        )}
      </>
    )
  );
};

const FullImageDisplay = ({
  nextFn,
  previousFn,
  imageToDisplay,
  machineTitle,
  closeFn,
}) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        previousFn();
      } else if (event.key === "ArrowRight") {
        nextFn();
      } else if (event.key === "Escape") {
        closeFn();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [nextFn, previousFn]);

  return (
    <div className="relative flex justify-center items-center">
      <LeftArrow onClick={previousFn} />
      <img
        src={imageToDisplay}
        alt={machineTitle}
        className="w-full max-h-[80vh] object-contain"
      />
      <RightArrow onClick={nextFn} />
    </div>
  );
};

const LeftArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute left-0 top-1/2 transform -translate-y-1/2 text-white bg-black bg-opacity-50 z-[51]"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="w-8 h-8"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 19l-7-7 7-7"
      />
    </svg>
  </button>
);

const RightArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute right-0 top-1/2 transform -translate-y-1/2 text-white bg-black bg-opacity-50 z-[51]"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="w-8 h-8"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 5l7 7-7 7"
      />
    </svg>
  </button>
);

const Details = ({ attributes, heading }) => (
  <div>
    <Heading size="text-md">{heading}</Heading>
    <div className="border-l border-r border-b border-grey pt-2 pl-2 pr-2 pb-4">
      <div className="border border-grey">
        {attributes.map((attribute, index) => (
          <React.Fragment key={index}>
            <Property
              label={attribute.key}
              value={attribute.value}
              bordered={index < attributes.length - 1}
              alternateBackground={index % 2 !== 0}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  </div>
);

const Property = ({ label, value, bordered, alternateBackground }) => (
  <div
    className={`flex justify-between text-xs p-2 ${
      bordered ? "border-b" : ""
    } ${alternateBackground ? "" : "bg-gray-100"}`}
  >
    <span>{label}</span>
    <span>{value}</span>
  </div>
);

const RecommendedSection = ({ activeMachineId, category, manufacturer }) => {
  const { machines } = useContext(MachineContext);

  const filteredMachines = machines
    .filter(
      (machine) =>
        (machine.id !== activeMachineId && machine.category === category) ||
        machine.manufacturer === manufacturer
    )
    .slice(0, 4);

  return (
    filteredMachines.length > 0 && (
      <div className="bg-secondary p-4 sm:p-16">
        <div className="container mx-auto max-w-7xl">
          <div className="flex mb-6">
            <Heading>Rekommenderat</Heading>
            <Link
              to={`/catalogue?category=${category}`}
              className="bg-black text-white p-1 self-start border-r border-t border-b border-primary"
            >
              Visa alla
            </Link>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {filteredMachines.map((machine) => (
              <MachinePreview machine={machine} key={machine.id} />
            ))}
          </div>
        </div>
      </div>
    )
  );
};

const MachineNotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-[75vh] p-4 max-w-2xl mx-auto">
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2 text-center text-secondary">
        Ojdå, den här maskinen finns inte!
      </h1>
      <div className="text-base sm:text-base md:text-base text-center">
        <p className="mb-8 text-gray-700">
          Detta kan bero på att maskinen du letar efter redan blivit såld, eller
          att den utgått från vårat utbud. Om du tror att detta är ett fel,
          vänligen{" "}
          <Link
            to="/contact"
            className="underline text-blue-400 hover:text-blue-600"
          >
            kontakta oss
          </Link>
          .
        </p>
        <Link to="/catalogue" className="text-primary hover:font-bold">
          Sök bland våra andra maskiner
        </Link>
      </div>
    </div>
  );
};

export default Machine;
