import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { NewsContext } from "../context/NewsContext";
import Button from "../components/button.jsx";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext.jsx";
import Heading from "../components/heading.jsx";
import Input from "../components/input.jsx";
import MarkdownDisplay from "../components/markdownDisplay.jsx";
import {
  deleteNews,
  toggleNewsPublished,
  updateNewsArticle,
} from "../httpService.js";
import { Helmet } from "react-helmet-async";
import CompanyBar from "../components/companyBar.jsx";

const News = () => {
  const { id } = useParams();
  const { isAdmin, getJwt } = useContext(UserContext);
  const { news, reinitialize } = useContext(NewsContext);
  const navigate = useNavigate();
  const /**@type NewsArticle*/ article = news.find(
      (article) => article.id === parseInt(id, 10)
    );

  const [sendingRequest, setSendingRequest] = useState(false);
  const [titleError, setTitleError] = useState(null);
  const [imageError, setImageError] = useState(null);

  const [imageFile, setImageFile] = useState(null);
  const [newsData, setNewsData] = useState({
    id: 0,
    authorName: "",
    title: "",
    imageUrl: "",
    body: "",
    published: false,
    createdAt: "",
  });

  useEffect(() => {
    if (!!article) {
      setNewsData({
        id: article.id || 0,
        authorName: article.authorName || "",
        title: article.title || "",
        imageUrl: article.imageUrl || "",
        body: article.body || "",
        published: article.published || false,
        createdAt: article.createdAt || "",
      });
    }
  }, [article]);

  const handleImageChange = (e) => {
    const newImage = e.target.files[0];
    setImageFile(newImage);
  };

  const handleInputChange = (e) => {
    setNewsData({ ...newsData, [e.target.name]: e.target.value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSendingRequest(true);
    let hasErrors = false;

    if (!newsData.title) {
      setTitleError("Titel krävs");
      hasErrors = true;
    } else {
      setTitleError(null);
    }

    if (imageFile && imageFile.type !== "image/jpeg") {
      setImageError("Endast jpg (jpeg) bilder är tillåtna");
      hasErrors = true;
    } else {
      setImageError(null);
    }

    if (hasErrors) {
      setSendingRequest(false);
      return;
    }

    try {
      setSendingRequest(true);
      await updateNewsArticle(imageFile, article.id, newsData, getJwt());
      reinitialize();
      alert("Nyhet uppdaterad");
      setSendingRequest(false);
    } catch (error) {
      alert("Kunde inte uppdatera maskin");
      setSendingRequest(false);
    }
  };

  /** @param article {NewsArticle} */
  function formatAuthorNameAndPublishDate(article) {
    if (article.authorName === "") {
      return "Publicerad " + article.createdAt.toLocaleDateString();
    } else {
      return `Publicerad av ${article.authorName} ${article.createdAt.toLocaleDateString()}`;
    }
  }

  /** @param article {NewsArticle} */
  const handleDeleteNews = async (article) => {
    try {
      if (
        !window.confirm(
          "Är du säker på att du vill ta bort den här nyhetsartikeln?"
        )
      ) {
        return;
      }
      await deleteNews([article.id], getJwt());
      alert("Artikel borttagen!");
      reinitialize();
      navigate(-1);
    } catch (error) {
      alert("Kunde inte radera artikel");
    }
  };

  /**@param article {NewsArticle} */
  const handleTogglePublishNews = async (article) => {
    try {
      const publish = !article.published;
      await toggleNewsPublished(article.id, publish, getJwt());
      alert("Artikel " + (publish ? "publicerad" : "avpublicerad"));
      reinitialize();
    } catch (error) {
      alert("Kunde inte ändra artikelns publicitet");
    }
  };

  return (
    article && (
        <>
          <Helmet>
            <title>AS Maskiner - {article.title}</title>
            <meta name="description" content={article.body} />
            <script type="application/ld+json">
              {`{
                        "@context": "https://schema.org",
                        "@type": "News",
                        "url": "https://www.asmaskiner.se/news/${article.id}",
                        "name": "News",
                        "description": ${article.body}
                    }`}
            </script>
          </Helmet>
          <CompanyBar/>
          <div className="container mx-auto p-8 max-w-4xl">
            <img
                src={article.imageUrl}
                alt="Article"
                className="w-auto max-w-full mx-auto mb-8 max-h-[450px]"
            />
            <Link to="/news" className="font-bold">
              ← Tillbaka till nyheter
            </Link>
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 mt-4">
              {article.title} {article.published ? "" : "(ej publicerad)"}
            </h1>
            <p className="text-gray-600 text-sm mb-8">
              {formatAuthorNameAndPublishDate(article)}
            </p>
            <MarkdownDisplay data={article.body}></MarkdownDisplay>

            {isAdmin && (
                <>
                  <form
                      onSubmit={handleSave}
                      className="flex flex-col gap-8 border border-primary p-4 mt-4"
                  >
                    <Heading style={{ width: "fit-content" }}>
                      Ändra information
                    </Heading>
                    <div>
                      <Input
                          id="title"
                          label="Titel"
                          type="text"
                          name="title"
                          value={newsData.title}
                          onChange={handleInputChange}
                          error={titleError}
                          required
                      />
                    </div>
                    <div>
                      <Input
                          id="image"
                          name="image"
                          label="Ändra bild"
                          type="file"
                          accept="image/jpg"
                          onChange={handleImageChange}
                          error={imageError}
                      />
                    </div>
                    <div>
                      <label
                          htmlFor="body"
                          className="block text-gray-700 text-sm font-bold mb-2"
                      >
                        Innehåll{" "}
                        <a
                            href="https://www.markdownguide.org/cheat-sheet/"
                            target="_blank"
                            className="text-blue-300"
                        >
                          (markdown)
                        </a>
                      </label>
                      <textarea
                          id="body"
                          name="body"
                          value={newsData.body}
                          onChange={handleInputChange}
                          rows="6"
                          required
                          className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
                      />
                      <label className="block text-gray-700 text-sm font-bold mb-2 pt-4">
                        Förhandsvisning
                      </label>
                      <MarkdownDisplay sx="border p-4" data={newsData.body} />
                    </div>
                    <div>
                      <Input
                          id="authorName"
                          name="authorName"
                          type="text"
                          label="Författare"
                          value={newsData.authorName}
                          onChange={handleInputChange}
                          className="border border-gray-300 w-full p-2 rounded"
                      />
                    </div>
                    <div className="space-x-4 flex">
                      <Button type="submit" loading={sendingRequest}>
                        Spara
                      </Button>
                      <div>
                        <Button
                            onClick={() => {
                              handleDeleteNews(article);
                            }}
                        >
                          Radera nyhet
                        </Button>
                      </div>
                      <div>
                        <Button
                            type="button"
                            onClick={() => handleTogglePublishNews(article)}
                        >
                          {article.published
                              ? "Avpublicera nyhet"
                              : "Publicera nyhet"}
                        </Button>
                      </div>
                    </div>
                  </form>
                </>
            )}
          </div>
        </>
    )
  );
};

export default News;
