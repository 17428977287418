import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext.jsx";
import { NewsContext } from "../context/NewsContext.jsx";
import { useBlocker, useNavigate } from "react-router-dom";
import Input from "../components/input";
import MarkdownDisplay from "../components/markdownDisplay";
import Checkbox from "../components/checkbox";
import Button from "../components/button";
import {
  createNewsArticle,
  deleteNews,
  unpublishMachine,
} from "../httpService";

const CreateNews = () => {
  const { isAdmin, getJwt, initialized } = useContext(UserContext);
  const navigate = useNavigate();
  const { reinitialize } = useContext(NewsContext);

  const initialNewsData = {
    /**@type {string} */ title: "",
    /**@type {File|null} */ image: null,
    /**@type {string} */ body: "",
    /**@type {boolean} */ published: true,
    /**@type {string} */ authorName: "",
  };

  const [newsData, setNewsData] = useState(initialNewsData);

  const [titleError, setTitleError] = useState(null);
  const [imageError, setImageError] = useState(null);

  const [isSendingRequest, setIsSendingRequest] = useState(false);

  const handleInputChange = (e) => {
    setNewsData({ ...newsData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    const newImage = e.target.files[0];
    setNewsData({ ...newsData, image: newImage });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSendingRequest(true);

    let hasErrors = false;

    if (!newsData.title) {
      setTitleError("Lägg till en titel");
      hasErrors = true;
    } else {
      setTitleError(null);
    }

    if (!newsData.image) {
      setImageError("Bild måste väljas");
      hasErrors = true;
    } else if (newsData.image.type !== "image/jpeg") {
      setImageError("Endast jpg (jpeg) bilder är tillåtna");
      hasErrors = true;
    } else {
      setImageError(null);
    }

    if (hasErrors) {
      setIsSendingRequest(false);
      return;
    }

    try {
      const generatedId = await createNewsArticle(
        {
          authorName: newsData.authorName,
          title: newsData.title,
          image: newsData.image,
          body: newsData.body,
          published: newsData.published,
        },
        getJwt()
      );
      alert("Nyhet skapad!");
      reinitialize();
      navigate(`/news/${generatedId}`);
    } catch (error) {
      alert("Kunde inte skapa nyhet");
    } finally {
      setIsSendingRequest(false);
    }
  };

  return (
    isAdmin && (
      <div className="container mx-auto p-8 max-w-7xl">
        <h2 className="text-3xl font-bold mb-8">Skapa nyhet</h2>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
          <div>
            <Input
              id="title"
              name="title"
              type="text"
              label="Titel"
              value={newsData.title}
              onChange={handleInputChange}
              error={titleError}
              required
            />
          </div>
          <div>
            <Input
              id="image"
              name="image"
              label="Bild"
              type="file"
              accept="image/jpg"
              onChange={handleImageChange}
              error={imageError}
              required
            />
          </div>
          <div>
            <label
              htmlFor="body"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Innehåll{" "}
              <a
                href="https://www.markdownguide.org/cheat-sheet/"
                target="_blank"
                className="text-blue-300"
              >
                (markdown)
              </a>
            </label>
            <textarea
              id="body"
              name="body"
              value={newsData.body}
              onChange={handleInputChange}
              rows="6"
              required
              className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
            />
            <label className="block text-gray-700 text-sm font-bold mb-2 pt-4">
              Förhandsvisning
            </label>
            <MarkdownDisplay sx="border p-4" data={newsData.body} />
          </div>
          <div>
            <Input
              id="authorName"
              name="authorName"
              type="text"
              label="Författare"
              value={newsData.authorName}
              onChange={handleInputChange}
              className="border border-gray-300 w-full p-2"
            />
          </div>
          <div>
            <Checkbox
              id="publish"
              name="publish"
              label="Publicera nyhet"
              checked={newsData.published}
              onCheck={() => setNewsData({ ...newsData, published: true })}
              onUnCheck={() => setNewsData({ ...newsData, published: false })}
            />
          </div>

          <Button type="submit" loading={isSendingRequest}>
            Skapa nyhet
          </Button>
        </form>
      </div>
    )
  );
};

export default CreateNews;
