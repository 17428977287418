import React from "react";
import Contact from "../components/contact";
import Heading from "../components/heading";
import { Helmet } from "react-helmet-async";
import CompanyBar from "../components/companyBar.jsx";

const SellMachine = () => {
  return (
    <>
      <Helmet>
        <title>AS Maskiner - Sälj maskin</title>
        <meta
          name="description"
          content="Sälj din maskin snabbt och enkelt till AS Maskiner. Vi erbjuder en smidig försäljningsprocess och konkurrenskraftiga priser för din trä- och plastbearbetningsutrustning. Kontakta oss idag för en kostnadsfri värdering."
        />
      </Helmet>
      <CompanyBar />
      <div className="container mx-auto p-8 sm:max-w-4xl">
        <Heading sx="mb-4 w-fit" type="h1">
          Kontakt
        </Heading>
        <Contact />
      </div>
    </>
  );
};

export default SellMachine;
