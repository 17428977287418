export const contact = {
  url: "/contact",
  name: "Kontakt",
};

const routes = {
  usedMachines: {
    url: "/catalogue?condition=Använd",
    name: "Begagnade maskiner",
  },
  newMachines: {
    url: "/catalogue?condition=Ny",
    name: "Nya maskiner",
  },
  sellMachines: {
    url: "/machines/sell",
    name: "Sälj maskin",
  },
  aboutUs: {
    url: "/about-us",
    name: "Om oss",
  },
  nyheter: {
    url: "/news",
    name: "Nyheter",
  },
  contact: contact,
};

export default routes;
