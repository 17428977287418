import React from "react";

// TODO: find out why accept={...} doesn't work
const Input = ({ error, id, label, required, ...props }) => {
  return (
    <>
      <label
        htmlFor={id}
        className="block text-gray-700 text-sm font-bold mb-2"
      >
        {label} {required && <span className="text-required">*</span>}
      </label>
      <input
        {...props}
        id={id}
        className={`appearance-none border ${
          error ? "border-red-500" : "border-gray-300"
        } w-full py-2 px-3 text-gray-700 leading-tight`}
      />
      {error && <span className="text-red-500 text-xs">{error}</span>}
    </>
  );
};

export default Input;
