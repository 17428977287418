import React from "react";

const CompanyBar = () => {
  return (
    <div className="relative flex flex-col items-center justify-center p-4 min-h-[12vh] text-white">
      <div className="absolute inset-0 bg-[url('/images/warehouse4.webp')] bg-cover bg-center"></div>
      <div className="absolute inset-0 bg-secondary/50 backdrop-blur-[4px]"></div>
      <h1 className="text-3xl lg:text-5xl font-bold z-10">
        AS Maskiner Sverige AB
      </h1>
      <p className="mt-4 text-base sm:text-lg max-w-sm sm:max-w-none text-center z-10">
        Sveriges största utbud av begagnade kvalitetsmaskiner för trä- och
        plastindustrin
      </p>
    </div>
  );
};

export default CompanyBar;
