import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "./UserContext.jsx";
import { getNews } from "../httpService.js";

const NewsContext = React.createContext({});

const NewsContextProvider = (props) => {
  const [news, setNews] = useState([]);

  const { getJwt, isAdmin, initialized } = useContext(UserContext);

  const initializeData = async () => {
    const news = await getNews(getJwt());
    setNews(news);
  };

  useEffect(() => {
    initializeData();
  }, []);

  useEffect(() => {
    if (initialized && isAdmin) {
      initializeData();
    }
  }, [isAdmin, initialized]);

  return (
    <NewsContext.Provider
      value={{
        news,
        reinitialize: initializeData,
      }}
      {...props}
    />
  );
};

export { NewsContextProvider, NewsContext };
